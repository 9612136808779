import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import styled from 'styled-components';
import { formatDate } from 'common/dateFunctions';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import BlogBaseCard from 'components/blog/BaseCard';
import { BlogPagesLinks } from 'components/Layout/navigation';
import { blogPostsPage4 } from 'common/blogPosts';
import ArrowButton from 'components/Button/ArrowButton';
import HeroBg from 'images/blog-hero.png';
import { ImageBox, GatsbyImage } from 'components/pages/Partners/styled';
import { BasicDiv } from 'components/wrappers';
import Optin from 'components/pages/Optin';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  Wrapper,
  LeftSection,
  RightSection,
  ArticlesContainer,
  PostsWrapper,
} from 'components/pages/Blog/styled';
import { HeaderMedium, BaseP, BaseTransparentP } from 'components/typography';
import Badge from 'components/Badge';
import { RelativeWrapper } from 'components/wrappers';
import { MobileFilter } from 'components/BackgroundFilter';

const BlogContainer = styled.div`
  padding-bottom:50px;
  width:100%;
  
  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }
  
  form {
    margin-top:0 !important;
    margin-bottom:0 !important;
    margin:0 !important;
  }
  .optin-form {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top:30px;
    margin-bottom:30px;
  }

  .optin-header {
    font-size: 27px;
    font-weight: 500;
    line-height: 1.5;
  }

  .optin-desc {
    font-size:18px;
    line-height: 1.5;
    margin-top:5px;
  }

  @media only screen and (max-width:1023px) {
    .column-half.last {
      width:100%;
    }

    .column-half {
      width:100%;
      padding-right:0;
    }

    form {
      padding-left:0 !important;
      padding-right:0 !important;
      margin-top:20px !important;
    }
    .optin-form {
      max-width:100%;
    }
  }
`;

const BlogRow = styled.div<ArticlesContainer>`
  margin-top:50px;
  border-bottom:1px solid #000;
  padding-bottom:35px;

  ${PostsWrapper} {
      grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
  }
`;


const Row  = styled.div<BasicDiv>`
  max-width:1100px;
  margin-left:auto;
  margin-right:auto;
  position:relative;
  
  ${HeaderMedium} {
    margin-top:50px;
  }
  @media only screen and (max-width:1023px) {
    padding-left:35px;
    padding-right:35px;
  }

`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 10px;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;

const Clear  = styled.div<BasicDiv>`
  clear:both;
  padding:0 !important;
`;

const MainSection = styled.div<HeroSection>`
  position:relative;

  #hero-section {
    padding:150px 0px;
    max-width: 1100px;
    margin: auto;
  }

  #hero-section .column-whole {
    max-width: 410px;
    padding: 20px;
    /*box-shadow: rgba(0,0,0,0.4) 0px 5px 13px 20px;*/
    /*background: rgba(0,0,0,0.4);*/
  }

  #hero-section h1 {
    color:#000;
    font-weight:500;
  }

  #hero-section p {
    color:#000;
    font-size:16px;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left:-2px;
    object-fit: cover;
    right:-2px;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  @media only screen and (max-width:1023px) {
    #hero-section .column-half {
      width: 100%;
      padding:0;
    }

    #hero-section .column-half img{
      width: 100%;
    }

    #hero-section .column-half p{
      padding-right:0;
    }

    #hero-section .column-half.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 0px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }

`;
const Breadcrumbs = styled.p`
    margin-bottom:25px;
`;

const BlogPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgBlogPage4HeaderQuery {
      file(relativePath: { eq: "mountains_.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet>
        {/* <title>eCommerce Shipping & Fulfillment Blog - Page 4 | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "blog-meta-title-ecommerce-shipping-fulfillment-blog4" })}</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "blog4-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "blog4-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "blog4-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "blog4-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Grow your business and get to know more the latest ecommerce trends and cross border shipping updates from Shipkoo's blog. - Page 4" />
        <meta property="og:title" content={intl.formatMessage({ id: "blog-meta-title-ecommerce-shipping-fulfillment-blog4" })} />
        <meta property="og:description" content="Grow your business and get to know more the latest ecommerce trends and cross border shipping updates from Shipkoo's blog. - Page 4" />
        <meta property="og:image" content={HeroBg} />
        <meta name="twitter:title" content={intl.formatMessage({ id: "blog-meta-title-ecommerce-shipping-fulfillment-blog4" })} />
        <meta name="twitter:description" content="Grow your business and get to know more the latest ecommerce trends and cross border shipping updates from Shipkoo's blog. - Page 4" />
      </Helmet>
      <RelativeWrapper>
        <MainSection>
          <div id="hero-section">
            <img className="hero-bg" src={HeroBg} alt="airplane wing" />
            <Row>
              <div className="column-whole">
                <Breadcrumbs><Link to="/">Home</Link> / Blog </Breadcrumbs>
                <HeroHeader>Our Blog</HeroHeader>
                  <HeroText>Get inspired, gain knowledge and <br />get to know us by reading our posts.</HeroText>
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>
      </RelativeWrapper>
      <BlogContainer>
      <Row>
        <HeaderMedium>News and Views</HeaderMedium>
        <BlogRow>  
          <PostsWrapper>
            {blogPostsPage4.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
        <TabNavigator tabs={BlogPagesLinks} activeTab={BlogPagesLinks[4].value} />
       </Row>
       <Row>
          <div className="optin-form">
          <div className="column-half">
            <h3 className="optin-header">Sign Up for Shipkoo Updates</h3>
            <p className="optin-desc">Get weekly insights about shipping and feature updates</p>
          </div>
          <div className="column-half last">
            <Optin />
          </div>
          <Clear></Clear>
         </div> 
       </Row> 
      </BlogContainer>
    </Layout>
  );
};

export default BlogPage;
